import { useEffect, useRef, useState } from 'react';
import { Section } from '../../snippets';
import { Icons } from '../../snippets/Icons';
import { FadeIn } from '../../animations/FadeIn';

import { Schema } from './MainHeroV2.schema';
import { Button } from '../../snippets/button';

export function MainHeroV2({ cms }) {
  const { buttons } = cms;
  const [loadingVideo, setLoadingVideo] = useState(false);
  const videoDesktopRef = useRef(null);
  const videoMobileRef = useRef(null);

  useEffect(() => {
    setLoadingVideo(true);
    setTimeout(() => {
      videoDesktopRef?.current?.load();
      videoMobileRef?.current?.load();
      videoDesktopRef?.current?.play();
      videoMobileRef?.current?.play();
      setLoadingVideo(false);
    }, 300);
  }, [cms]);

  return (
    <Section cms={cms}>
      {/* Container with gradient background */}
      <div className="northern-lights-gradient-v2 flex flex-col items-center gap-8 pb-[60px] md:gap-[60px] md:px-20 md:pb-[120px] md:pt-[60px]">
        <div className="mx-auto flex max-w-8xl flex-col items-center gap-8 px-5 pt-6 md:gap-5 md:px-[60px] md:pt-0">
          {/* Text Wrapper */}
          <div className="flex flex-col items-center gap-6">
            {cms.headingLine1 || cms.headingLine2 ? (
              <h1 className="text-center leading-[3rem] antialiased md:leading-[4.25rem]">
                {/* Heading Line 1 */}
                {cms.headingLine1 && (
                  <span className="text-primary-light">{cms.headingLine1}</span>
                )}

                {cms.headingLine1 && cms.headingLine2 && <br />}

                {/* Heading Line 2 */}
                {cms.headingLine2 && (
                  <span className="text-gradient">{cms.headingLine2}</span>
                )}
              </h1>
            ) : null}

            {/* Subheading */}
            {cms.subheading && (
              <p className="max-w-[700px] text-center font-aeonik text-lg leading-6 text-grey-200 antialiased md:px-6">
                {cms.subheading}
              </p>
            )}
          </div>

          {/* Buttons */}
          {buttons && buttons.length > 0 ? (
            <div className="flex w-full flex-col gap-2 md:w-auto md:flex-row md:gap-3">
              {buttons.map((button, index) => (
                <Button
                  key={`main-hero-button-${index}`}
                  link={button.link}
                  icon={button.icon}
                  iconPosition={button.iconPosition}
                />
              ))}
            </div>
          ) : null}
        </div>

        {/* Media Wrapper */}
        <div className="relative mx-auto flex max-w-8xl flex-col items-center gap-3">
          {/* Hero Video */}
          {cms.videoDesktop || cms.videoMobile ? (
            <FadeIn delayAnimationSeconds={300} threshold={0}>
              <div className="flex justify-center px-2">
                <div className="flex justify-center rounded-lg bg-[rgba(13,13,13,0.3)] p-2 md:order-3 md:rounded-[28px] md:p-[14px]">
                  <div
                    className={`aspect-video overflow-hidden rounded shadow-sm transition-opacity duration-300 md:rounded-[15px] ${
                      loadingVideo ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    {cms.videoDesktop ? (
                      <video
                        ref={videoDesktopRef}
                        className="you-cursor hidden h-full w-full object-cover md:block"
                        poster={cms.imageDesktop?.src}
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source src={cms.videoDesktop} type="video/mp4" />
                      </video>
                    ) : null}
                    {cms.videoMobile ? (
                      <video
                        ref={videoMobileRef}
                        className="you-cursor h-full w-full object-cover md:hidden"
                        poster={cms.imageMobile?.src}
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source src={cms.videoMobile} type="video/mp4" />
                      </video>
                    ) : null}
                  </div>
                </div>
              </div>
            </FadeIn>
          ) : null}

          {/* Hero Image */}
          {!cms.videoMobile &&
            !cms.videoDesktop &&
            (cms.imageDesktop || cms.imageMobile) && (
              <div className="flex justify-center px-4 md:order-2 md:pt-[52px]">
                <picture className="w-full max-w-7xl">
                  {cms.imageDesktop && cms.imageMobile && (
                    <source
                      srcSet={cms.imageDesktop?.src}
                      media="(min-width: 768px)"
                    />
                  )}

                  <img
                    className="w-full"
                    src={cms.imageDesktop?.src || cms.imageMobile?.src}
                    alt={cms.imageDesktop?.alt || cms.imageMobile?.alt}
                  />
                </picture>
              </div>
            )}

          <div className="flex items-center gap-2 rounded-full bg-[rgba(13,13,13,0.15)] py-1 px-6 backdrop-blur-md md:absolute md:right-[68px] md:bottom-[60px]">
            <div className="-ml-5 flex h-8 w-8 items-center justify-center rounded-full bg-primary-dark md:h-10 md:w-10">
              <Icons icon="shopify" />
            </div>
            <p className="font-aeonik text-xs leading-5 text-primary-dark antialiased md:text-sm md:leading-6">
              Built for Shopify, powered by Hydrogen
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}

MainHeroV2.displayName = 'MainHeroV2';
MainHeroV2.Schema = Schema;
