import { AlignLeft } from '../icons/alignLeft';
import { ArrowLeft } from '../icons/arrowLeft';
import { ArrowLeftButton } from '../icons/arrowLeftButton';
import { ArrowRight } from '../icons/arrowRight';
import { Atom } from '../icons/atom';
import { Bars } from '../icons/bars';
import { Book } from '../icons/book';
import { Brackets } from '../icons/brackets';
import { CaretDown } from '../icons/caretDown';
import { Checkmark } from '../icons/checkmark';
import { ChevronDown } from '../icons/chevronDown';
import { Clock } from '../icons/clock';
import { ConnectedBlocks } from '../icons/connectedBlocks';
import { Copy } from '../icons/copy';
import { Dashboard } from '../icons/dashboard';
import { Disabled } from '../icons/disabled';
import { Elements } from '../icons/elements';
import { ElementsPlus } from '../icons/elementsPlus';
import { File } from '../icons/file';
import { Globe } from '../icons/globe';
import { GreenCircleCheckmark } from '../icons/greenCircleCheckmark';
import { Grid } from '../icons/grid';
import { Hamburger } from '../icons/hamburger';
import { Info } from '../icons/info';
import { InfoEmpty } from '../icons/infoEmpty';
import { Key } from '../icons/key';
import { Laptop } from '../icons/laptop';
import { Layers } from '../icons/layers';
import { Lightning } from '../icons/lightning';
import { Link } from '../icons/link';
import { LogoRebrandSquare } from '../icons/logoRebrandSquare';
import { LogoColorRebrandSquare } from '../icons/logoColorRebrandSquare';
import { Modules } from '../icons/modules';
import { Monitor } from '../icons/monitor';
import { Play } from '../icons/play';
import { Refresh } from '../icons/refresh';
import { Rocket } from '../icons/rocket';
import { Route } from '../icons/route';
import { Script } from '../icons/script';
import { Select } from '../icons/select';
import { Send } from '../icons/send';
import { Share } from '../icons/share';
import { Shop } from '../icons/shop';
import { Shopify } from '../icons/shopify';
import { ShoppingCart } from '../icons/shoppingCart';
import { Sliders } from '../icons/sliders';
import { Slides } from '../icons/slides';
import { Stack } from '../icons/stack';
import { Stopwatch } from '../icons/stopwatch';
import { Storefront } from '../icons/storefront';
import { Terminal } from '../icons/terminal';
import { Union } from '../icons/union';
import { UserCheck } from '../icons/userCheck';
import { Users } from '../icons/users';
import { Verified } from '../icons/verified';
import { Video } from '../icons/video';
import { X } from '../icons/x';

export function Icons({ icon, ...rest }) {
  switch (icon) {
    case 'alignLeft':
      return <AlignLeft {...rest} />;
    case 'arrowLeft':
      return <ArrowLeft {...rest} />;
    case 'arrowLeftButton':
      return <ArrowLeftButton {...rest} />;
    case 'arrowRight':
      return <ArrowRight {...rest} />;
    case 'atom':
      return <Atom {...rest} />;
    case 'bars':
      return <Bars {...rest} />;
    case 'book':
      return <Book {...rest} />;
    case 'brackets':
      return <Brackets {...rest} />;
    case 'caretDown':
      return <CaretDown {...rest} />;
    case 'checkmark':
      return <Checkmark {...rest} />;
    case 'chevronDown':
      return <ChevronDown {...rest} />;
    case 'clock':
      return <Clock {...rest} />;
    case 'connectedBlocks':
      return <ConnectedBlocks {...rest} />;
    case 'copy':
      return <Copy {...rest} />;
    case 'dashboard':
      return <Dashboard {...rest} />;
    case 'disabled':
      return <Disabled {...rest} />;
    case 'elements':
      return <Elements {...rest} />;
    case 'elementsPlus':
      return <ElementsPlus {...rest} />;
    case 'file':
      return <File {...rest} />;
    case 'globe':
      return <Globe {...rest} />;
    case 'greenCircleCheckmark':
      return <GreenCircleCheckmark {...rest} />;
    case 'grid':
      return <Grid {...rest} />;
    case 'hamburger':
      return <Hamburger {...rest} />;
    case 'info':
      return <Info {...rest} />;
    case 'infoEmpty':
      return <InfoEmpty {...rest} />;
    case 'key':
      return <Key {...rest} />;
    case 'laptop':
      return <Laptop {...rest} />;
    case 'layers':
      return <Layers {...rest} />;
    case 'lightning':
      return <Lightning {...rest} />;
    case 'link':
      return <Link {...rest} />;
    case 'logoRebrandSquare':
      return <LogoRebrandSquare {...rest} />;
    case 'logoColorRebrandSquare':
      return <LogoColorRebrandSquare {...rest} />;
    case 'modules':
      return <Modules {...rest} />;
    case 'monitor':
      return <Monitor {...rest} />;
    case 'play':
      return <Play {...rest} />;
    case 'refresh':
      return <Refresh {...rest} />;
    case 'rocket':
      return <Rocket {...rest} />;
    case 'route':
      return <Route {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'send':
      return <Send {...rest} />;
    case 'script':
      return <Script {...rest} />;
    case 'share':
      return <Share {...rest} />;
    case 'shop':
      return <Shop {...rest} />;
    case 'shopify':
      return <Shopify {...rest} />;
    case 'shoppingCart':
      return <ShoppingCart {...rest} />;
    case 'sliders':
      return <Sliders {...rest} />;
    case 'slides':
      return <Slides {...rest} />;
    case 'stack':
      return <Stack {...rest} />;
    case 'stopwatch':
      return <Stopwatch {...rest} />;
    case 'storefront':
      return <Storefront {...rest} />;
    case 'terminal':
      return <Terminal {...rest} />;
    case 'union':
      return <Union {...rest} />;
    case 'userCheck':
      return <UserCheck {...rest} />;
    case 'users':
      return <Users {...rest} />;
    case 'verified':
      return <Verified {...rest} />;
    case 'video':
      return <Video {...rest} />;
    case 'x':
      return <X {...rest} />;
    default:
      return null;
  }
}
